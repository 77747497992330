"use strict";Object.defineProperty(exports, "__esModule", {value: true});var _styles = require('@mui/material/styles');
var _typography = require('./typography');
var _pallete = require('./pallete');
var _components = require('./components');
var _colors = require('./colors');
var _constants = require('./constants');

const baseThemeDefinition = {
  palette: _pallete.darkModePallete,
  spacing: _constants.DEFAULT_SPACING,
  shape: {
    borderRadius: _constants.DEFAULT_BORDER_RADIUS
  }
};
const baseTheme = _styles.createTheme.call(void 0, baseThemeDefinition);
const darkTheme = _styles.createTheme.call(void 0, {
  ...baseThemeDefinition,
  typography: _typography.buildTypographyVariant.call(void 0, "dark"),
  palette: _pallete.darkModePallete,
  components: _components.darkModeComponents
});
const lightTheme = _styles.createTheme.call(void 0, {
  ...baseThemeDefinition,
  typography: _typography.buildTypographyVariant.call(void 0, "light"),
  palette: _pallete.lightModePallete,
  components: _components.lightModeComponents
});







exports.SPACING = _constants.SPACING; exports.baseColors = _colors.baseColors; exports.baseTheme = baseTheme; exports.colors = _colors.colors; exports.darkTheme = darkTheme; exports.lightTheme = lightTheme;
